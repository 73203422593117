// Import necessary modules and components
import React, { useState, useEffect, useRef } from 'react';
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';   
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';   
import { Container, Typography, Box, TextField, Button, CircularProgress } from '@mui/material';
import awsconfig from './aws-exports';
import {
  LinkedinShareButton,
  EmailShareButton,
  WhatsappShareButton,
} from 'react-share';
import {
  LinkedinIcon,
  EmailIcon,
  WhatsappIcon,
} from 'react-share';


// Configure Amplify
Amplify.configure(awsconfig);

// Chatbot Component
const Chatbot = () => {
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [hasStartedTyping, setHasStartedTyping] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL; 
  const apiKey = process.env.REACT_APP_API_KEY; 
  const messagesEndRef = useRef(null);

  // Scroll to bottom when messages change
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  // Function to send message
  const sendMessage = async () => {
    if (!userInput.trim()) return; // Do not send empty messages

    // Add user's message
    setMessages((prevMessages) => [...prevMessages, { text: userInput, sender: 'user' }]);
    setUserInput('');
    setIsLoading(true);

    try {
      const response = await fetch(`${apiUrl}/chatbot`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': apiKey,
        },
        body: JSON.stringify({ inputText: userInput }),
      });

      const data = await response.json();
      console.log('API Response:', data);

      if (response.ok) {
        const botText = data.processedText || 'Sorry, I could not understand the response.';
        // Directly set the bot's message without typing effect
        setMessages((prevMessages) => [...prevMessages, { text: botText, sender: 'bot' }]);
      } else {
        const errorText = data.message || 'Unknown error occurred.';
        console.error('API Error:', response.status, errorText);
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            text: `Error: Could not connect to the chatbot. ${
              response.status === 404 ? 'Please check the API URL.' : ''
            }`,
            sender: 'error',
          },
        ]);
      }
    } catch (error) {
      console.error('Error:', error);
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          text: 'An error occurred. Please try again later.',
          sender: 'error',
        },
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ 
      position: 'relative',
      display: 'flex', 
      flexDirection: 'column', 
      height: '80vh', 
      width: '100%', 
      maxWidth: 600, 
      margin: '0 auto', 
      border: '1px solid #e0e0e0', 
      borderRadius: 2, 
      backgroundColor: '#f5f5f5',
    }}>
      {/* Messages container */}
      <Box sx={{ 
        flexGrow: 1, 
        overflowY: 'auto', 
        padding: 2,
      }}>
        {/* Display initial placeholder text */}
        {messages.length === 0 && !hasStartedTyping && (
          <Box sx={{ mb: 2 }}>
            <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
              AI Intelligence for Brazil iGaming.
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              Coming in Jan 2025: daily game and operator analytics.
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              Feel free to ask me about the market, the regulations and operators.
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              Some suggestions: xxxxx xxxxxxxxx xxxxx xxxxxxxxx? xxxxx xxxxxxxxx xxxxx xxxxxxxxx? xxxxx xxxxxxxxx xxxxx xxxxxxxxx?
            </Typography>
          </Box>
        )}

        {/* Display messages */}
        {messages.map((message, index) => (
          <Box 
            key={index} 
            sx={{
              display: 'flex',
              justifyContent: message.sender === 'user' ? 'flex-end' : 'flex-start',
              mb: 1,
            }}
          >
            <Box
              sx={{
                maxWidth: '80%',
                padding: 1,
                borderRadius: 1,
                backgroundColor: message.sender === 'user' ? '#1976d2' : '#e0e0e0',
                color: message.sender === 'user' ? 'white' : 'black',
              }}
            >
              <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap' }}>
                {message.text}
              </Typography>
            </Box>
          </Box>
        ))}
        <div ref={messagesEndRef} />
      </Box>

      {/* Input area */}
      <Box sx={{ 
        borderTop: '1px solid #e0e0e0', 
        padding: 2, 
        backgroundColor: '#f0f0f0',
      }}>
        <Box sx={{ display: 'flex' }}>
          <TextField 
            variant="outlined"
            placeholder="Type your message..."
            value={userInput}
            onChange={(e) => {
              setUserInput(e.target.value);
              if (!hasStartedTyping && e.target.value.trim() !== '') {
                setHasStartedTyping(true); // Update the state when user starts typing
              }
            }}
            fullWidth
            disabled={isLoading}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                sendMessage();
              }
            }}
          />
          <Button 
            variant="contained" 
            color="primary" 
            onClick={sendMessage} 
            disabled={isLoading || !userInput.trim()}
            sx={{ marginLeft: 1 }}
          >
            Send
          </Button>
        </Box>
      </Box>

      {/* Loading spinner overlay */}
      {isLoading && (
        <Box sx={{ 
          position: 'absolute', 
          top: 0, 
          left: 0, 
          right: 0, 
          bottom: 0, 
          backgroundColor: 'rgba(255, 255, 255, 0.7)', 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center',
        }}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

// Home Component with Sign Out Button
const Home = ({ signOut }) => (
  <Container>
    {/* Sign Out Button at the Top Right */}
    <Box sx={{ position: 'relative', mb: 2 }}>
      <Button 
        onClick={signOut} 
        size="small" 
        sx={{ 
          position: 'absolute', 
          top: 0, 
          right: 0, 
          fontSize: '0.75rem',
          textTransform: 'none',
        }}
      >
        Sign Out
      </Button>
      <Typography variant="h4" gutterBottom>
        Slot Intel Brazil
      </Typography>
    </Box>
    <Chatbot /> {/* Chatbot component (grey box area) */}

    {/* Share Buttons Below the Chatbot */}
    <Box sx={{ 
      mt: 2, // Add margin-top to create space above
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <Typography variant="body2" sx={{ mr: 1 }}>
        Share:
      </Typography>
      <LinkedinShareButton url="https://slotintel.com/brazil" style={{ marginRight: 8 }}>
        <LinkedinIcon size={32} round />
      </LinkedinShareButton>
      <EmailShareButton url="https://slotintel.com/brazil" style={{ marginRight: 8 }}>
        <EmailIcon size={32} round />
      </EmailShareButton>
      <WhatsappShareButton url="https://slotintel.com/brazil">
        <WhatsappIcon size={32} round />
      </WhatsappShareButton>
    </Box>
  </Container>
);

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Authenticator>
        {({ signOut, user }) => (
          user && <Home signOut={signOut} /> 
        )}
      </Authenticator>
    </ThemeProvider>
  );
};

export default App;